<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" href="#">
       <img alt="Vue logo" class="logo" src="../assets/logo.jpg">
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div class="navbar-nav">
        <router-link class="nav-item nav-link active" to="/">Home</router-link>
        <router-link class="nav-item nav-link" to="/about">About</router-link>
        <router-link class="nav-item nav-link" to="/login" v-if="!$store.state.user">Se connecter</router-link>
        <router-link class="nav-item nav-link" to="/register" v-if="!$store.state.user">S'inscrire</router-link>
        <a href="javascript:void(0)" class="nav-item nav-link" @click="handleClick" v-if="$store.state.user">Se deconnecter</a>
        <!-- <a class="nav-item nav-link active" href="#">Home <span class="sr-only">(current)</span></a> -->
        <!-- <a class="nav-item nav-link" href="#">Features</a>
        <a class="nav-item nav-link" href="#">Pricing</a>
        <a class="nav-item nav-link disabled" href="#">Disabled</a> -->
        </div>
    </div>
    </nav>
</template>

<script>
export default {
    name: 'Nav',

    // props: ['user'],

    methods: {
        handleClick() {

            localStorage.removeItem('token')

            this.$store.dispatch('setUser', null)

            this.$router.push('/login')
        }
        
    }
}
</script>

<style>

</style>