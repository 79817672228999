<template>
    <div class="home" v-if="$store.state.user">
      <!-- <img alt="Vue logo" src="../assets/logo.jpg"> -->
      <!-- <Nav /> -->
        <div class="jumbotron jumbotron-fluid">
        <div class="container">
          <h1 class="display-4">Bonjour {{ $store.state.user.name }}</h1>
          <p class="lead">This is a modified jumbotron that occupies the entire horizontal space of its parent.</p>
        </div>
    </div>
  </div>
</template>

<script>

// import axios from 'axios'
// @ is an alias to /src
// import Nav from '@/components/Nav.vue'

export default {
  name: 'Home',
  // props: ['user']
  
}
</script>
