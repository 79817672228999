import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null
  },

  mutations: {
    setUser(state, newUser) {
      state.user = newUser
    },
  },

  actions: {
    setUser(context, user) {
      context.commit('setUser', user)
    }
  },

  modules: {
  }

})
