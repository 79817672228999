<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <!-- <Nav :user="user" />
    <router-view  :user="user"/> -->
    <Nav />
    <router-view />
  </div>
</template>


<script>
// @ is an alias to /src
import Nav from '@/components/Nav.vue';
// import axios from 'axios';

export default {
  components: {
    Nav
  },

  // data() {
  //   return {
  //     user: null
  //   }
  // },

  // async created() {
      
  //     try {
  //         const response = await axios.get('api/user')
  //         console.log('response', response)
  //         this.user = response.data;

  //         this.$store.dispatch('setUser', response.data)
    
  //     } catch (error) {
  //         // this.errors = error.response.data.errors
  //         console.log('error', console.log(error.response.data))
  //     }
  // }

}

</script>



<style>

</style>
